.ant-divider-horizontal{
    margin-bottom: -15px !important;
    margin-top: 50px !important;
}

.bio_image_container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-top: 75px;
    margin-left: 50px;
}

.bio_page{
    display: flex;
    justify-content: space-around;
}

.bio_image_container > img{
    width: 30vw;
}

.desc_start{
    font-size: 32px;
}

.desc_text{
    margin-top: 5px;
}

.desc_text p{
    margin-bottom: 0px;
}

.desc{
    position: absolute;
    display: flex;
    top: 200px;
    right: 30px;
}

.sub_title{
margin-bottom: -15px;
}

.library_video{
    width: 32vw;
    height: 17.76vw;
}

.library_video__container{
    margin-left: 2.7vw;
}

.TextSpace_Wider{
    width: 75%;
    margin: auto;
    min-height: 85vh;
    
}

.TextSpace_Wider .ant-divider-inner-text{
    max-width: 70vw;
    white-space: normal;
    margin-bottom: 50px;
}

.TextSpace_Wider .ant-divider-horizontal.ant-divider-with-text-left::after{
    min-width: 25%;
    width: auto;
}

.seminar_images img{
    width: 20vw;
}

.certificate_container{
    margin-left: 50px;
    padding: 20px;
}

.certificate_container .ant-divider-horizontal.ant-divider-with-text{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.ArabicRtl{
    direction: rtl;
}

@media screen and (max-width: 999px) {

    .bio_image_container{
        margin-left: 0;
    }

    .bio_image_container > img{
        width: 80vw;
    }

    .Library.TextSpace_Wider{
        width: 90%;
    }

    .library_video{
        width: 42vw;
        height: 23.3vw;
    }
  }

@media screen and (max-width: 795px) {

    .seminar_images img{
        width: 70vw;
        margin-bottom: 30px;
        margin-left: 15px;
    }
  }

  @media screen and (max-width: 690px) {

    .library_video{
        width: 90vw;
        height: 47.8125vw;
    }
    
    .library_video__container{
        margin-left: 0;
    }
    
       .TextSpace_Wider{
        width: 90%;
    }

    .certificate_container{
        margin-left: 0;
    }
  }

  

@media screen and (max-width: 600px) {

    .ant-divider-inner-text{
        max-width: 70vw;
        white-space: normal;
    }
    
    .ant-divider-horizontal.ant-divider-with-text-left::after{
        width: 30% !important;
        min-width: 25%;
        width: auto;
    }
  }


.AboutMe_Text h1{
    margin-left: 2rem;
}

.AboutMe{
    white-space: pre-wrap;
    background-color: white;
    
}

.AboutMe h2{
    max-width: 95vw;
}

.ant-divider-horizontal.ant-divider-with-text {
    margin-bottom: -15px '';
}

.AboutMeArabic{
direction: rtl;
white-space: pre-wrap;
background-color: white;

}

.ArabicRtl{
    direction: rtl;
}

.title{
    background-color: #f7f7f7;
    display:flex;
    height: 5rem;
    align-items: center;
    justify-content: space-evenly;
    width: 98.9vw;
    border-style: solid;
    border-width: 1px;
    border-color: #d5d5d5;
    margin-bottom: 10px;
}

.title h1{
    font-size: large;
}


.TextSpace{
    width: 65%;
    margin: auto;
    min-height: 85vh;
    
}

.TextSpace > .ant-divider-inner-text{
    max-width: 70vw !important;
    white-space: normal;
    margin-bottom: 50px;
}

.ant-divider-horizontal{
    margin-bottom: -15px !important;
}

@media only screen and (max-width: 1300px) {
    .TextSpace{
        margin-top: 40px;
    }

    .TextSpace > .ant-divider-inner-text{
        max-width: 70vw !important;
        white-space: normal;
        margin-bottom: 50px;
    }
    
    .TextSpace ant-divider-horizontal.ant-divider-with-text-left::after{
        min-width: 25%;
        width: auto;
    }
  }

@media only screen and (max-width: 1000px) {
    .TextSpace{
        width: 80%;
    }

  }

@media only screen and (max-width: 700px) {
    .TextSpace{
        width: 90%;
    }
  }
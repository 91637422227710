.ImageCard{
    margin: 2rem auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 2px solid grey;
    width: 240px;
    min-height: 450px;
    background-color: snow;
    box-shadow: 5px 5px 10px grey;
}

.ImageCard p{
    padding: 1rem 0.5rem;
    display: flex;
    font-weight: bold;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.ImageCard img{
    display: flex;
    width: auto;
    height: 135px;
    margin: auto;
    
}

.company_logo{
    border-bottom: grey 2px solid;
    padding-bottom: 1rem;
    
}
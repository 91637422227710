.home{
    white-space: pre-wrap;
    display: block;
    min-height: 100vh;
}

.home_area{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.home_area img{
    width: 100%;
}

.company_logo{
    margin-left: 2rem;
    margin-top: 2rem;
    width: 30%;
}

.hero_headline{
    font-size:xx-large;
    font-weight: 700;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    margin: 2rem 0;
    margin-top: -11rem;

}

.hero_text{
    font-size: x-large;
}

.hero{
    display: flex;
    justify-content: flex-start;
    align-self: center;
    width: 80%;
    
}

.hero{
    color: white;
}

.ant-btn{
    margin: 1rem
}

.video{
    display: flex;
    justify-content: space-evenly;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.video_space iframe{
 width: 30vw;
 height: 16.875vw;
}

.video_space .ant-btn, .ant-btn:active, .ant-btn:focus{
    margin-bottom: 2rem;
}

.bottom_page_button_container{
    width: 100%;
    display: flex;
}

.bottom_page_button{
    margin: auto;
    margin-top: -30px;
    color: white;
    font-size: 20px;
}

@media screen and (max-width: 1200px) {
    .video_space iframe{
        width: 85vw;
        height: 47.8125vw;
        margin-left: 7.5vw;
       }

       .video_space {
        margin-right: 0 !important;
       }
  }

  @media screen and (max-width: 685px) {
    .hero_headline{
        margin-top: 1rem;
    }

    .hero{
        color: black;
    }

    .hero_text{
       margin-bottom: 7rem;
    }

    .bottom_page_button_container{
        display: none;
    }
  }



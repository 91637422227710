.header {
    width: 100%;
    /*height: 170px;*/
    background-color: white;
    box-shadow: 2px 5px 8px #888888;
    display: inline-block;
    color: #000;
}
.header__top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color:whitesmoke;
    height: 2.5rem;
    width: 100%;
    margin-bottom: 1rem;
}

.header__main{
    display: flex;
}

.icon{
    margin-top: 3px;
    margin-left: 1rem;
    
}

.icon:hover{
    cursor: pointer;
}

.header__img {
    width: 18rem;
    margin-bottom: 1rem;
    margin-left: 2rem;
    
}

.header__main ul{
    display: flex;
    margin: auto;
    width: 75%;
    justify-content: flex-end;
    align-items: center;

}

.header__main__rtl ul{
    justify-content: flex-start;
}

.header__main li{
    display: inline;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    
}

.header__main Link{
    display: inline;
    list-style: none;
    margin-right: 2rem;
    
}

.header__main li:hover{
    color: orange;
    cursor: pointer;
    animation-name: li_animation;
    animation-duration: 0.5s;
}


@keyframes li_animation {
    from {color: black;}
    to {color: orange;}
  }

@media screen and (max-width: 1300px) {
    .header {
      margin-bottom: 20px;
      /*height: 150px;*/
    }

    .header__img {
        width: 15rem;
    }
  }

  @media screen and (max-width: 700px) {

    .header__img {
        width: 5rem;
    }
  }


